import React, {useState, useEffect} from 'react';
import Top from 'HeaderRestructure/Top';
import Menu from 'HeaderRestructure/Menu';
import MobileTop from 'HeaderRestructure/MobileTop';
import animateScrollTo from "animated-scroll-to";

const HeaderRestructure = (props) => {
    const menuBreakpoint = 1025;
    const pageHeaderHeight = 68;
    const [isMobile, setIsMobile] = useState(window.innerWidth < menuBreakpoint);
    const [mobileMenuHeight, setMobileMenuHeight] = useState(null);

    useEffect(() => {
        window.addEventListener('resize', checkWindowWidth);
        window.addEventListener('scroll', checkOffset);
        checkWindowWidth();
        checkOffset();
    },[]);
    const checkWindowWidth = () => {
        setIsMobile(window.innerWidth < menuBreakpoint);
        setMobileMenuHeight(window.innerHeight - pageHeaderHeight);
    }
    const backToTop = (e) => {
        e.preventDefault();
        const options = {
            speed: 2000
        }
        animateScrollTo(document.querySelector("html"), options);
    }
    const checkOffset = () => {
        const backToTopButton = document.querySelector(".backToTop");
        if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
            backToTopButton.classList.remove("hidden");
        } else {
            backToTopButton.classList.add("hidden");
        }

    }

    return (
        <div className="pageHeader">
            {isMobile ? (
                <React.Fragment>
                    <MobileTop {...props} menuHeight={mobileMenuHeight} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Top {...props} />
                    {props.Menu && (
                        <Menu {...props} />
                    )}
                </React.Fragment>
            )}
            <a href="#" className="backToTop" data-test="back-top" onClick={e => backToTop(e)}></a>
        </div>
    );
}
export default HeaderRestructure;
