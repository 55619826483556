import React, {useState} from 'react';
import ProfessionalsList from 'Header/ProfessionalsList';
import HeaderLinks from 'Header/HeaderLinks';
import Picture from '../Shared/Picture';

const MobileMenu = (props) => {
    const [showSubMenu, setShowSubMenu] = useState(null);

    return (
        <div className="mobileMenu" style={{"height": props.menuHeight + "px" }}>
            <nav>
                {props.showCountrySelector ? (
                    <ul>
                        {props.Countries.map((country, index) => (
                            <li className="mainMenuItem" key={index}>
                                <a href={window.location.protocol +"//" + country.Url}>{country.Name}</a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <React.Fragment>
                        <ul>
                            {showSubMenu === null && props.Menu.map((item) => (
                                <li onClick={() => {setShowSubMenu(item.Id)}} className="mainMenuItem" key={item.Id}>
                                    {item.Url.Value !== "" ? (
                                        <a href={item.Url.Value} dangerouslySetInnerHTML={{ __html: item.Text.Value}} />
                                    ) : (
                                        <span dangerouslySetInnerHTML={{ __html: item.Text.Value}} />
                                    )}
                                </li>
                            ))}
                            {props.Menu.map((item) => (
                                showSubMenu === item.Id ? (
                                    <React.Fragment key={item.Id}>
                                        <span onClick={() => {setShowSubMenu(null)}} className="back"  dangerouslySetInnerHTML={{ __html: item.Text.Value}} />
                                        {item.MenuItems.length > 0 && (
                                            <ul className="subMenu">
                                                {item.MenuItems.map((subItem) => (
                                                    <li key={subItem.Id} className={subItem.AdditionalClass.Value + " column"}>
                                                        {subItem.Image.Value[1] !== "" && subItem.Image.Value[1] !== null && (
                                                            <Picture src={subItem.Image.Value} alt={subItem.ImageAlt.Value} className="bannerImage" />
                                                        )}
                                                        {subItem.MenuItems.length > 0 ? (
                                                            <ul>
                                                                {subItem.MenuItems.map((element) => (
                                                                    <li key={element.Id} className={element.AdditionalClass.Value + " subMenuItem"}>
                                                                        {element.Url.Value !== "" ? (
                                                                            <a href={element.Url.Value}>{element.Text.Value}</a>
                                                                        ) : (
                                                                            <span>{element.Text.Value}</span>
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : null}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </React.Fragment>
                                ) : null
                            ))}
                        </ul>
                        {showSubMenu === null && (
                            <React.Fragment>
                                {props.LinksImagesSection.length > 0 && (
                                    <HeaderLinks {...props.LinksImagesSection[1]}/>
                                )}
                                <ProfessionalsList ListName={props.ListName} Elements={props.Elements}/>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </nav>
        </div>
    );
}
export default MobileMenu;
