import React from 'react';
import ReactDOM from 'react-dom';
import Footer from './Footer/Footer';
// import Header from './Header/HeaderRedesign';
import Header from './HeaderRestructure/HeaderRestructure';

if (document.getElementById("Layout") !== null) {
    let layout = JSON.parse(document.getElementById("Layout").textContent); // eslint-disable-next-line
    let professionalsList = JSON.parse(document.getElementById("ProfessionalsList").textContent);
    ReactDOM.render(<Header {...professionalsList} {...layout.Model } />, document.getElementById('header'));
    if (layout.Model.Footer !== null) {
        ReactDOM.render(<Footer {...layout.Model.Footer} />, document.getElementById('footer'));
    }
}
