import React, { Component } from 'react';

class HeaderLinks extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <ul className="menu-links">
                {this.props.ImageLinksPairs.map((link => {
                    return (
                        <li key={link.Id}>
                            <a target={link.UrlTarget.Value} href={link.Url.Value} className={link.CssClassName.Value}>{link.Text.Value}</a>
                        </li>
                    )
                }))}
            </ul>
        );
    }

}
export default HeaderLinks;
