import React, {useState} from 'react';
import Logo from 'Header/Logo';
import MobileMenu from 'HeaderRestructure/MobileMenu';
import Search from 'HeaderRestructure/Search';

const MobileTop = (props) => {
    const [showSearchForm, setShowSearchForm] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const lang = document.getElementById("divlang").getAttribute("data-id");
    const [showCountrySelector, setShowCountrySelector] = useState(false);

    let htmlClassList = document.getElementsByTagName("html")[0].classList;

    if (showMenu) {
        htmlClassList.add("is-menu-presented");
    } else {
        htmlClassList.remove("is-menu-presented");
    }
    return (
        <React.Fragment>
            <div className="mobileTop">
                <span onClick={() => {
                    setShowMenu(!showMenu);
                    setShowCountrySelector(false);
                }} className={showMenu ? "menuClose" : "menuOpen"}></span>
                <Logo />
                {showMenu ? (
                    <div className={(showCountrySelector ? "active " : "") + "countrySelector"}>
                        <span onClick={() => {setShowCountrySelector(!showCountrySelector)}}>{lang}</span>
                    </div>
                ) : (
                    <span onClick={() => {setShowSearchForm(!showSearchForm)}} className="searchToggle"></span>
                )}
            </div>
            {showSearchForm && (
                <Search placeholder={props.InputSearch} showForm={setShowSearchForm} mobile={true} />
            )}
            {showMenu && (
                <MobileMenu {...props} showCountrySelector={showCountrySelector} />
            )}
        </React.Fragment>
    );
}
export default MobileTop;
