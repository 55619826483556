import React from 'react';

const logo = () => {
    const lang = document.getElementById("divlang").getAttribute("data-id");
    const url = location.origin + "/" + lang;

    return (
        <div className="logo">
            <a href={url} data-test="header-logo">
                <img src="/i/header/logo.png" alt="NIOXIN" />
            </a>
        </div>
    )
};
export default logo;
