import React, { Component } from 'react';
import Picture from 'Shared/Picture';

class PopupNioxin extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <div className={this.props.popup ? ("global-popin-wrapper buyNow active"): ("global-popin-wrapper buyNow")}>
                    <div className="global-popin-scroller">
                        <div className="global-popin-centered">
                            <section className="popinTemplate2 buyOnlinePopup NoRetailStorePopup">
                                <h3>{this.props.pushesPopup.isPopupMenu == true ? this.props.pushesPopup.TitlePopup : this.props.pushesPopup[0].Title.Value}</h3>
                                <div className="clearfix">
                                    {this.props.pushesPopup.isPopupMenu == true ? (
                                        this.props.pushesPopup.Arritems.map((push) => (
                                            <div className="buyOnlinePopup__block buyOnlinePopup__block--side" key={push.Id}>
                                                <a className="links-to-app" href={push.Url.Value} target={push.ImageUrlTarget.Value} data-test="consultation-app-link">
                                                    <Picture src={push.Image.Value} alt={push.ImageAlt.Value}/>
                                                </a>
                                            </div>
                                        ))
                                    ) : (
                                        this.props.pushesPopup[0].Pushes.map((push) => (
                                            <div className="buyOnlinePopup__block buyOnlinePopup__block--side">
                                                <h4>
                                                    <span className="colorText">{push.Title.Value}</span>
                                                    <span className="grayText" dangerouslySetInnerHTML={{"__html": push.Subtitle.Value }} />
                                                </h4>
                                                {push.ButtonText.Value !== "" && (
                                                    <a className="button default btn" href={push.ButtonUrl.Value}>{push.ButtonText.Value}</a>
                                                )}
                                            </div>
                                        ))
                                    )}
                                </div>
                                <button onClick={(event)=>this.props.hidePopUp(event, "nioxin")} type="button" title="Close (Esc)" className="close">×</button>
                            </section>
                        </div>
                    </div>
                </div>
                <div className={this.props.popup ? ("global-popup-overlay buyNow active") : ("global-popup-overlay buyNow buyNow")}></div>
            </div>
        );
    }
}
export default PopupNioxin;
