import React, { useState, useCallback, useEffect } from 'react';
import PopupNioxin from '../Shared/PopupNioxin';

const ProfessionalsList = props => {
    const [popup, setPopup] = useState(false);
    const [Arritems, setArritems] = useState([]);
    const [TitlePopup, setTitlePopup] = useState('');
    const [isPopupMenu, setIsPopupMenu] = useState(false);
    const pushesPopup = {Arritems, TitlePopup, isPopupMenu};
    const [showList, setShowList] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    const hidePopup = useCallback(
        event => {
            event.preventDefault();
            setPopup(!popup);
        }, [popup]
    );
    const showPopup = (event, items, ispopup, title) => {
        event.preventDefault();
        setPopup(!popup);
        setArritems(items);
        setIsPopupMenu(ispopup);
        setTitlePopup(title)
    };
    useEffect(() => {
        setIsMobile(window.innerWidth < 1025);
        setShowList(isMobile);
    }, []);
    return (
        <React.Fragment>
            <div className="professionals">
                <p
                    className={(showList ? "active " : "") + "professionals__title"}
                    onClick={() => {setShowList(!showList)}}
                    onKeyDown={(e) => {
                        if (e. key === 'Enter') {
                            let professionalsList = document.getElementById("professionalsList");
                            if (professionalsList.style.display !== "block") {
                                professionalsList.style.display = "block";
                            } else {
                                professionalsList.style.display = "none";
                            }
                        }
                    }}
                    tabindex="0"
                >
                    {props.ListName}
                </p>
                {(showList || !isMobile) ? (
                    <ul className="professionals__list" id="professionalsList">
                        {props.Elements.map((element, key) => {
                            return (
                                <li className="professionals__list--item" key={key} data-test="professionals-item">
                                    <a target="_blank"
                                        onClick={event => element.ProfessionalsPopup !== null && (
                                            showPopup(event, element.ProfessionalsPopup.PopupImages, element.ProfessionalsPopup !== null, element.ProfessionalsPopup.Title.Value)
                                        )}
                                        href={element.LinkUrl}
                                    >
                                        {element.ProfessionalsElementName}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                ) : null}
            </div>
            {popup && (
                <PopupNioxin
                    hidePopUp={hidePopup}
                    popup={popup}
                    pushesPopup={pushesPopup}
                />
            )}
        </React.Fragment>
    )
};
export default ProfessionalsList;
