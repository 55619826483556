import React from 'react';

const SubscribeButton = (props) => {
    const {url, text, cssClass, target} = props;
    return (
        <div className={`${cssClass}-right`}>
            <a href={url} target={target}>{text}</a>
        </div>
    )
};
export default SubscribeButton;
