import React from "react"

const ImpressumPopup = ({ impressumPopup }) => {
    return (
        <div className="impressum-popup">
            <div className="content">
                <h3 className="pink">IMPRESSUM</h3>
                <br />
                <p>
                    Wella Germany GmbH<br />
                    Berliner Allee 65-65a,<br />
                    64295 Darmstadt
                    <br /><br />
                    Geschäftsführer:<br />Dr. Henrik Rolf Haverkamp, Thomas Bär
                    <br /><br />
                    Vorsitzende des Aufsichtsrats: Ilkan Eryagci<br />
                    Sitz in Darmstadt - Amtsgericht: Darmstadt HRB 94871, Ust.ID: DE 304310603
                </p>
                <button title="Close (Esc)" type="button" className="close" onClick={impressumPopup}>×</button>
            </div>
        </div>
    );
}

export default ImpressumPopup;
