import React from 'react';
import ProfessionalsList from 'Header/ProfessionalsList';
import HeaderLinks from 'Header/HeaderLinks';
import Language from 'Header/Language';
import Logo from 'Header/Logo';
import SubscribeButton from 'Header/SubscribeButton';

const Top = (props) => {
    const {LinksImagesSection, LinksImagesRightSection, ListName, Countries, Elements} = props;
    return (
        <div className="headerTop">
            <div className="links">
                {LinksImagesSection.length > 0 && LinksImagesSection[1] && (
                    <HeaderLinks {...LinksImagesSection[1]} />
                )}
                {ListName !== "" ? (
                    <ProfessionalsList ListName={ListName} Elements={Elements} />
                ) : null}
                {Countries && (
                    <Language countries={Countries} />
                    )}
                {LinksImagesRightSection[0] && LinksImagesRightSection[0].ImageLinksPairs[0].Text.Value !== "" ?
                    <SubscribeButton
                        text={LinksImagesRightSection[0].ImageLinksPairs[0].Text.Value}
                        url={LinksImagesRightSection[0].ImageLinksPairs[0].Url.Value}
                        target={LinksImagesRightSection[0].ImageLinksPairs[0].UrlTarget.Value}
                        cssClass={LinksImagesRightSection[0].ImageLinksPairs[0].CssClassName.Value}
                    />
                    : null
                }
            </div>
            <Logo />
        </div>
    );
}
export default Top;
