import React, {Component} from 'react';

class Language extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryUrl: window.location.href,
            countryCode: [],
            defaultCountry: null
        };
        this.show = this.show.bind(this);
    }

    componentWillMount() {
        var countries = [];
        var defaultCountryCode = "sv-SE";
        this.props.countries.map((c) => {
            if (c.CurrentLanguage === true) {
                this.setState({
                    defaultCountry: c
                })
                if (c.Name === "International") {
                    c.CurrentLanguage = false;
                    const unitedStates = this.props.countries.filter(country => country.Name === "United States");
                    unitedStates[0].CurrentLanguage = true;
                    this.setState({
                        defaultCountry: unitedStates[0]
                    });
                    countries.push(c);
                }
            } else {
                countries.push(c);
            }
        })
        this.setState({
            countryCode: countries
        });
    }

    show(event) {
        event.preventDefault();
        var url = event.target.value;
        if (url.slice(0, 4) === "http" || url.slice(0, 5) === "https") {
            window.open(url, "_self");
        } else {
            window.open(`//${url}`, "_self");
        }
    }

    render() {
        var sortObjects = this.state.countryCode.sort((a, b) => {
            if (a.Name < b.Name) {
                return -1;
            }
            if (a.Name > b.Name) {
                return 1;
            }
            return 0;
        });
        return (
            <div className="language">
                <label for="language">Choose a country</label>
                <select value={this.state.value} id="language" onChange={this.show} tabindex="0">
                    <option value={this.state.defaultCountry.Url}>{this.state.defaultCountry.Name}</option>
                    {sortObjects.map((country, index) => {
                        return <option key={index} data-url={country.Url}
                                    value={country.Url}>{country.Name}</option>
                    })}
                </select>
            </div>
        );
    }
}

export default Language;
