import React, { useState } from 'react';
import Search from 'HeaderRestructure/Search';
import Picture from 'Shared/Picture';

const Menu = (props) => {
    const [showSubMenu, setShowSubMenu] = useState(null);

    return (
        <nav className="headerMenu">
            <ul className="mainMenu">
                {props.Menu.map((item) => (
                    <li className="mainMenuItem"
                        onMouseEnter={() => setShowSubMenu(item.Id)}
                        onMouseLeave={() => setShowSubMenu(null)}
                        key={item.Id}
                        data-test="mainmenu-item"
                    >
                        <div
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    if (showSubMenu) {
                                        setShowSubMenu(null)
                                    } else {
                                        setShowSubMenu(item.Id)
                                    }
                                }
                            }}
                            role="link"
                            aria-expanded={item.MenuItems.length > 0 && showSubMenu === item.Id ? true : false}
                            tabindex="0"
                            className="mainMenuLink"
                        >
                            {item.Url.Value !== "" ? (
                                <a href={item.Url.Value} dangerouslySetInnerHTML={{ __html: item.Text.Value }} />
                            ) : (
                                <span dangerouslySetInnerHTML={{ __html: item.Text.Value }} />
                            )}
                        </div>
                        {item.MenuItems.length > 0 && showSubMenu === item.Id ? (
                            <div className="subMenu">
                                <div className="subMenuContainer">
                                    <ul>
                                        {item.MenuItems.map((subItem) => (
                                            <li key={subItem.Id} className={subItem.AdditionalClass.Value + " column"} data-test="menu-column">
                                                {subItem.Text.Value !== "" ? (
                                                    subItem.Url.Value !== "" ? (
                                                        <a href={subItem.Url.Value}>{subItem.Text.Value}</a>
                                                    ) : (
                                                        <span>{subItem.Text.Value}</span>
                                                    )
                                                ) : null}
                                                {subItem.Image.Value[0] !== null && (
                                                    <Picture src={subItem.Image.Value} alt={subItem.ImageAlt.Value} className="bannerImage" />
                                                )}
                                                {subItem.MenuItems.length > 0 ? (
                                                    <ul>
                                                        {subItem.MenuItems.map((element) => (
                                                            <li className={element.AdditionalClass.Value} key={element.Id} data-test="menu-item">
                                                                {element.Url.Value !== "" ? (
                                                                    <a href={element.Url.Value}>{element.Text.Value}</a>
                                                                ) : (
                                                                    <span>{element.Text.Value}</span>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : null}
                                            </li>
                                        ))}
                                    </ul>
                                    {item.MenuItemImages.length > 0 ? (
                                        <div className="images">
                                            <ul>
                                                {item.MenuItemImages.map((image, index) => (
                                                    <li key={index}>
                                                        <Picture
                                                            src={image.Image.Value}
                                                            alt={image.ImageAltText.Value}
                                                        />
                                                        {image.Title.Value !== "" || image.LinkName.Value !== "" ? (
                                                            <div className="imageOverlay">
                                                                {image.Title.Value !== "" && (
                                                                    <a target={image.ImageUrlTarget.Value} href={image.ImageUrl.Value}>{image.Title.Value}</a>
                                                                )}
                                                                {image.LinkName.Value !== "" && (
                                                                    <span dangerouslySetInnerHTML={{ __html: image.LinkName.Value }} />
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </li>
                )
                )}
            </ul>
            <Search placeholder={props.InputSearch} />
        </nav>
    );
}
export default Menu;
