import React, { useState, useEffect } from 'react';
import ImpressumPopup from './impressum';

const detectBrowsers = () => {
    const isSafari = /constructor/i.test(window.HTMLElement) || (p => { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    const isIE = false || !!document.documentMode;
    const isEdge = !isIE && !!window.StyleMedia;

    if (isSafari || isIE || isEdge) return true;
}

const greekReplaceAccented = string => {
    const charList = { 'Ά': 'Α', 'ά': 'α', 'Έ': 'Ε', 'έ': 'ε', 'Ή': 'Η', 'ή': 'η', 'Ί': 'Ι', 'ί': 'ι', 'ΐ': 'ϊ', 'Ό': 'Ο', 'ό': 'ο', 'Ύ': 'Υ', 'ύ': 'υ', 'ΰ': 'ϋ', 'Ώ': 'Ω', 'ώ': 'ω', 'ς': 'Σ' };

    return string.replace(/./g, char => {
        return char in charList ? charList[char] : char;
    });
}

const replaceAccented = () => {
    const currentLang = document.documentElement.lang;
    if (currentLang !== "el" || !detectBrowsers()) return;

    const element = document.getElementsByTagName('*');

    if (typeof getComputedStyle == "undefined")
        getComputedStyle = element => {
            return element.currentStyle;
        };

    for (let i = 0; i < element.length; i++) {
        if (getComputedStyle(element[i]).textTransform === "uppercase") {
            element[i].innerHTML = greekReplaceAccented(element[i].innerHTML);
        }
    }
}

const Footer = (props) => {
    const [toggleImpressumPopup, setToggleImpressumPopup] = useState(false)

    const impressumPopup = (e) => {
        e.preventDefault();
        setToggleImpressumPopup(!toggleImpressumPopup);
    }

    useEffect(() => {
        replaceAccented();
    }, [])

    const yearNow = () => {
        return new Date().getFullYear();
    }

    const lang = document.getElementById("divlang").getAttribute("data-id");

    return (
        <React.Fragment>
            <footer>
                <section className="footer-links footer-1">
                    <p className="heading6">{props.LinksImagesSections[0].SectionName.Value}</p>
                    <ul>
                        {props.LinksImagesSections[0].ImageLinksPairs.map((link => {
                            return (
                                <li a target="_blank" key={link.Id}><a href={link.Url.Value}>{link.Text.Value}</a></li>
                            )
                        }))}
                    </ul>
                </section>
                <section className="footer-icon-links footer-2">
                    <p className="heading6">{props.LinksImagesSections[1].SectionName.Value}</p>
                    <ul>
                        {props.LinksImagesSections[1].ImageLinksPairs.map((link => {
                            return (
                                <li key={link.Id}><a target="_blank" href={link.Url.Value} className={link.CssClassName.Value} title={link.Text.Value}>{link.Text.Value}</a></li>
                            )
                        }))}
                    </ul>
                </section>
                <section className="footer-icon-links footer-3">
                    <p className="heading6">{props.LinksImagesSections[2].SectionName.Value}</p>
                    <ul>
                        {props.LinksImagesSections[2].ImageLinksPairs.map((link => {
                            return (
                                <li key={link.Id}><a target="_blank" href={link.Url.Value} className={link.CssClassName.Value} title={link.Text.Value} data-test={`social-${link.Text.Value}`}>{link.Text.Value}</a></li>
                            )
                        }))}
                    </ul>
                </section>
                <div className="footer-links all-row">
                    <ul>
                        {props.LinksImagesSections[3].ImageLinksPairs.map((link => {
                            return (
                                <li key={link.Id}><a target="_blank" href={link.Url.Value}>{link.Text.Value}</a></li>
                            )
                        }))}
                        {lang === "de-DE" && <li className="impressum" onClick={() => setToggleImpressumPopup(!toggleImpressumPopup)}>Impressum</li>}
                        <li><a href="#"><span id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</span></a></li>
                    </ul>
                </div>
                {props.TransparencyText && props.TransparencyText.Value ? (
                    <div className="transparency all-row"><a href={props.TransparencyUrl.Value} target="_blank">{props.TransparencyText.Value}</a></div>
                ) : null}
                <div className="copyright all-row">© {yearNow()}, {props.CopyRightsText.Value}</div>
            </footer>
            {toggleImpressumPopup && <ImpressumPopup impressumPopup={impressumPopup} />}
        </React.Fragment>
    )
}

export default Footer;
