import React, {useState, useEffect} from 'react';
import 'core-js/features/string/includes';

const Search = (props) => {
    const [searchText, setSearchText] = useState("");
    const lang = document.getElementById("divlang").getAttribute("data-id");
    const hideForm = (e) => {
        e.preventDefault();
        props.showForm(false);
    }
    const handleSubmit = (ev) => {
        ev.preventDefault();
        window.location = "/" + lang + "/search/" + searchText;
    }
    const checkSearchTextInUrl = () => {
        if (window.location.href.includes("/search/")) {
            setSearchText(decodeURI(window.location.href.split("/search/")[1]))
        }
    }
    useEffect(() => {
        checkSearchTextInUrl();
    },[]);
    return (
        <div className={props.mobile ? "mobileHeaderSearch" : "headerSearch"}>
            <form onSubmit={(ev) => handleSubmit(ev)}>
                <input type="submit" className="searchSubmit" value="search" />
                <label for="searchInputText">{props.placeholder.Value}</label>
                <input type="text" className="searchInputText" id="searchInputText" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder={props.placeholder.Value} />
                {props.mobile && (
                    <a href="#" className="close" onClick={(e) => hideForm(e)}></a>
                )}
            </form>
        </div>
    );
}
export default Search;
